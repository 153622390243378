.ProfilePicture {
	border-radius: 50%;
	height: 40px;
	width: auto;
	margin: 0px;
	padding: 0px;
}

.ShapeNeleks {
	background: linear-gradient(150deg, #318DFB, #53F6C4);
}
